import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import {
  Layout,
  Pagination,
  PaginationType,
  Flex,
  QaSearchResult,
  SearchBox,
  SEO,
  Wrapper,
  WordCloud,
} from '@renderbus/common/components'
import { color, typography, spacing, Media } from '@renderbus/common/theme'
import { LocationService } from '@renderbus/common/service'
import SearchEngine from '../components/search/search-engine'
import AdBanner from '../components/ad-banner'

export const SearchTip = styled.div`
  width: 100%;
  color: ${color.prompt};
  line-height: 24px;
  text-align: center;
  :first-of-type {
    margin-top: ${spacing.large};
    font-size: ${typography.text};
  }
  :last-of-type {
    margin-bottom: 90px;
  }
  font-size: ${typography.textSmall};
  > span {
    color: ${color.primary};
  }
`
export const SearchWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`
export const SearchBoxContainer = styled(Flex)`
  width: 100%;
  height: 120px;
  border-radius: 5px;
`
export const SearchResultContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin: ${spacing.base} 0;
  padding-bottom: ${spacing.large};
  border-radius: 5px;
  background-color: white;
`
export const SearchResultList = styled.div`
  width: 90%;
`
export const TipList = styled.div`
  width: 100%;
  margin: 0 auto;
`
const QaWrapper = styled(Wrapper)`
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(240, 240, 240);
  ${Media.lessThan(Media.small)} {
    background-color: white;
  }
`
const KeyWords = styled.div`
  width: 100%;
`
const KeyWordsText = styled.span`
  padding: 5px 10px;
  background-color: ${color.primary};
  color: white;
`
const AnswerTotal = styled.span`
  padding-left: 20px;
`
const AnswerTotalNum = styled.span`
  color: ${color.primary};
`
const GoHome = styled.a`
  color: ${color.primary};
  padding-right: 10px;
`
class SearchTemplate extends Component {
  constructor(props) {
    super(props)
    this.numPerPage = 20
    this.state = {
      currentPage: 1,
    }
  }
  componentDidMount() {
    const currentPage =
      parseInt(LocationService.getLocationSearchParams().get('page')) || this.state.currentPage
    this.setState({
      currentPage: currentPage,
    })
  }
  getCurrentPage = () => {
    if (this.props.location.state) {
      return parseInt(this.props.location.state.newPage) || this.state.currentPage
    }
    return this.state.currentPage
  }
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    const currentPage = this.getCurrentPage()
    let title = LocationService.getLocationSearchParams().get('query')
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO title={title || ''} description='' />
        <WordCloud wordCloudArray={data.term.nodes} />
        <AdBanner />
        <QaWrapper>
          <SearchEngine
            type='qa'
            render={({ type, query, result, handleSearch }) => {
              let total = Math.ceil(result.length / 10)
              return (
                <SearchWrapper>
                  <SearchBoxContainer>
                    <SearchBox searchContent={query} onSearch={handleSearch} type={type} />
                  </SearchBoxContainer>
                  <KeyWords>
                    <GoHome href='/'>渲染常见问题大全 &gt; </GoHome>
                    <KeyWordsText>{query}</KeyWordsText>
                    <AnswerTotal>
                      共找到<AnswerTotalNum> {result.length} </AnswerTotalNum>个相关问题
                    </AnswerTotal>
                  </KeyWords>
                  <SearchResultContainer>
                    <SearchResultList>
                      <QaSearchResult
                        result={result}
                        searchContent={query}
                        currentPage={currentPage}
                      />
                    </SearchResultList>
                    {result.length > 0 ? (
                      <Pagination
                        type={PaginationType.Dynamic}
                        total={total}
                        currentPage={currentPage}
                      />
                    ) : null}
                  </SearchResultContainer>
                </SearchWrapper>
              )
            }}
          />
          <TipList>
            <SearchTip>如果未返回查询结果，请尝试更改关键字或联系在线客服400-003-0560。</SearchTip>
            <SearchTip>
              有其他建议可通过电子邮件联系产品经理:
              <span>Steven@rayvision.com</span>
            </SearchTip>
          </TipList>
        </QaWrapper>
      </Layout>
    )
  }
}
export default SearchTemplate

export const CloudTermQuery = graphql`
  query {
    term: allContentfulQaTerm {
      nodes {
        name
        slug
      }
    }
    allList: allContentfulQa(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          createdAt(formatString: "YYYY-MM-DD kk:mm:ss")
          question
          slug
          tags {
            name
            slug
          }
        }
      }
    }
  }
`
